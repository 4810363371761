.locscoll{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin: auto;
}

.locscoll .left-r .right-r{
    height: 100vh;
    background-color: var(--orange);
}

/* @media screen and (max-width: 768px) {
    .locscoll{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
} */




