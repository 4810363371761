*{
   margin: 0;
   padding: 0;
   box-sizing: border-box; 
}
/* .slider{
    display: grid;
    place-items: center;
    display: flex;
    opacity: .6;
}
.slide-track{
    display: flex;

}
.slide{
    height: 50px;
    width: 100px;
    display: flex;
    align-items: center;
    padding: 15px;
}
.slide img{
    width: 100%;
}
.imageCont{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.slider:hover{
    transition-duration: all 350ms ease-in-out;
    opacity: 1;
    transform: scale(1.5);
    -webkit-transition: all 350ms ease-in-out;
    transition: all 350ms ease-in-out;
    
} */
.partners{
    width: 90vw;
    max-width: 1170px;
    margin: 5rem auto;
    display: grid;
    gap: 2rem
}
@media screen  and (min-width:768px){
    .partners{
        grid-template-columns: repeat(4, 1fr);
    }
}
.partner-logo{
background: #fff;
border-radius: 1rem;
padding: 2rem;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
}

.partner-logo img {
width: 100%;
object-fit: cover;
border-radius: 0.5rem;
}
.partner-logo h2{
margin-top: 1rem;
font-size: 1.25rem;
font-weight: bold;
text-align: center;
}
.partner-logo h4{
color:#617d98;
font-size: 1rem;
margin-top:  0.5em;
letter-spacing:2px;
text-align: center;
}

.social-links {
margin-top: 1rem;
display: flex;
justify-content: center;
gap: 0.5rem;
justify-content: space-between;
width: 100%; 
  }
  
  .social-links a {
    margin: 0 0.5rem;
    color: #617d98;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: #000;
  }
/* body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

color: #222;
} */