/* .inscriptions{
 background-image: url("/public/logos.png");
 background-position: center;
 background-repeat: no-repeat;

}

.errors{
    color: red;
}
 .p-inputnumber-input{
    padding: 8px !important;
} */
@import url("https://fonts.googleapis.com/css?family=Sofia&effect=fire|neon|outline|emboss|shadow-multiple");

.inscriptions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    background: url("/public/logos.png") center no-repeat;
    background-size: cover;
    text-align: center;
}

.inscriptions-form {
    background: rgba(0, 0, 0, 0.8);
    padding: 2rem;
    border-radius: 10px;
    /* max-width: 70%;  */
    width: 100%;
    max-width: 900px;
    
}

.form-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.form-header .stroke-text {
    font-size: 1.5rem;
    color: white;
}

.form-header .partner {
    font-size: 1.5rem;
    color: var(--orange);
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1rem;
}

.form-field {
    display: flex;
    flex-direction: column;
}

.form-label {
    font-size: 1rem;
    color: white;
    margin-bottom: 0.5rem;
}

.errors {
    color: red;
    font-size: 0.875rem;
}

.form-actions {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.btn-j {
    background-color: var(--orange);
    color: white;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.btn-j:hover {
    background-color: darkorange;
}

.success-message {
    margin-top: 1rem;
    text-align: center;
}
.text-font{
    font-family: "Sofia", sans-serif;
    color: white;
    font-size: 1rem;
    
}