/* .section{
    height: 100vh;
    scroll-snap-align: center;
    display: flex;
    justify-self: center;
    width: 100%;
}
.container{
    width: 100%;
    display: flex;
    justify-content: space-between;

}
.left{
    flex: 1;
    display: flex;
    align-items: center;

}
.right{
    flex: 1;
    
}
.uls{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.lis{
font-size: 100px;
font-weight: bold;
cursor: pointer;
color: transparent;
-webkit-text-stroke: 1px white;


}
li:hover{
    transition: 0.6s ease-in-out;
     color: var(--orange);
 }

 .gallerys{
    height: 175vh;
    background: rgba(45, 45, 45, 0.35);
    display: flex;
    flex-direction: row;
    gap: 2vw;
    padding: 2vw;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 15px;

 }
 .column{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2vw;
    min-width: 250px;
    position: relative;
 }
 .column &:nth-of-type(1){
    top: -45%;
 }
 .column &:nth-of-type(2){
    top: -95%;
 }
 .column &:nth-of-type(3){
    top: -45%;
 }
 .column &:nth-of-type(4){
    top: -75%;
 }
 .imageContainer{
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 1vw;
    overflow: hidden;
 }
 .img{
    object-fit: cover;
 }
 .spacer{
    height: 100vh;
 } */

 /* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */




/* 
.section {
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
}


.container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 2rem;
   padding: 0 2rem;
   box-sizing: border-box;
}


.left, .right {
   flex: 1;
}


.uls {
   list-style: none;
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.lis {
   font-size: 2rem; 
   font-weight: bold;
   cursor: pointer;
   color: transparent;
   -webkit-text-stroke: 1px white;
   transition: color 0.6s ease-in-out;
}

.lis:hover {
   color: var(--orange);
}


.gallerys {
   height: auto;
   background: rgba(45, 45, 45, 0.35);
   display: flex;
   flex-wrap: wrap; 
   gap: 2vw;
   padding: 2vw;
   border-radius: 15px;
   overflow: hidden;
}


.column {
   width: calc(25% - 1rem); 
   display: flex;
   flex-direction: column;
   gap: 2vw;
   min-width: 250px;
   position: relative;
}


.imageContainer {
   width: 100%;
   aspect-ratio: 1; 
   border-radius: 1rem; 
   overflow: hidden;
}


.img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   transition: transform 0.3s ease; 
}

.img:hover {
   transform: scale(1.05); 
} */

/* Add styles for the gallery */
.gallerys {
   display: flex;
   flex-wrap: wrap;
   gap: 2vw;
   padding: 2vw;
   border-radius: 15px;
   overflow: hidden;
   justify-content: center; /* Align items to the center horizontally */
}

/* Adjust styles for the column */
.column {
   flex: 1; /* Fill available space equally */
   max-width: calc(25% - 2vw); /* Adjust max-width to maintain uniformity */
   display: flex;
   flex-direction: column;
   gap: 2vw;
   position: relative;
}

/* Adjust image container styles */
.imageContainer {
   width: 100%;
   aspect-ratio: 1; /* Maintain aspect ratio */
   border-radius: 1vw; /* Adjust border radius */
   overflow: hidden;
}

/* Adjust image styles */
.img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   transition: transform 0.3s ease; /* Add transition for zoom effect */
}

/* Add hover effect for image container */
.imageContainer:hover .img {
   transform: scale(1.05); /* Zoom effect on hover */
}