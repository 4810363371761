
// .active{
//     background-color: #9a0110;
//     color: #fff;
//     border-radius: 4px;
//     transition: all 0.3s ease-in-out;
// }
// :root{
//     --PrimaryColor: hsla(0, 93%, 50%, 0.927);
//     --HoverColor: hsla(0, 93%,42%, 0.927);
//     --whiteColor: hsl(0, 0%, 100%);
//     --blackColor: hsl(0, 0%, 18%);
//     --textColor: hsl(240, 1%, 48%);
//     --breyBg: hsl(0, 0%, 96%);
//     --greyText: rgb(199, 190, 190);
//     --inputColor: hsl(330, 12%, 97%);
  
//   }

  
//    .navBarSection{
//         .header{
//             position: fixed;
//             display: flex;
//             align-items: center;
//             justify-content: space-between;
//             background: transparent;
//             width: 100%;
//             padding: 1rem;
//             z-index: 1000;

//         .logoDiv{
//             .logo{
//                 cursor: pointer;
//                 align-items: center;
//                 justify-content: center;
//                 width: 150px;
//                 height: auto;
//                 .icon{
//                     color:  var(--PrimaryColor);
//                     font-size: 25px;
//                  }
//                }
//         }
        
        
  
//  @media screen and (max-width: 768px){
//     .navBar{
//         background:  var(--breyBg);
//         position: absolute;
//         height: max-content;
//         padding: 1rem;
//         width: 100%;
//         top: 0;
//         left: -500%;
//         box-shadow: 0 2px 4px rgba(209, 209, 209, 0.904);
//         transition: .5s ease;
//         z-index: 1000;
//         .navLists{
//             flex-direction: column;
//             justify-content: center;
//             width: 100%;
//             margin: auto;
//             .navItem{
//                 padding: 1rem 0;
//                 .navLink{
//                     color: var(--textColor);
//                     font-size: .9rem;
//                     font-weight: 600;
//                 &:hover{
//                     color: var(--textColor);
//                 }
//             }
//         }
//     } }
//     .closeNavBar{
//         position:  absolute;
//         top: 1rem;
//         right: 1.5rem;
//         color: var(--PrimaryColor);
//         &:hover .icon{
//             color: var(--HoverColor)
//         }
//     }
    
//     .activeNavbar{
//         left: 0;
//     }
// }}
// }

.NavbarItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background-color: #222;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
    width: 95%;
    height: 65px;
    border-radius: 13px;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    z-index: 99999;
}
.navbar-logo{
    color: #222;
    font-size: 2rem;
    cursor: pointer;
}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    color: #fff;
}
  
 .nav-links{
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
 }
 .nav-links:hover{
    background-color: var(--orange);
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
 }

 .nav-links-mobile{
    display: none;
}
.menus-icons{
    display: none;
}
@media screen and (max-width: 850px) {
    .menus-icons{
        display: block;
        cursor: pointer;
    }
    .NavbarItems{
        z-index: 99;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #3d3d3d;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;

        grid-template-columns: repeat(6, auto);
        grid-gap: 10px;
        list-style: none;
        align-items: center;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;



    }
    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;


    }
    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }
    .nav-links:hover{
        background: var(--orange);
        transition: none;
    }
    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        width: 80%;
        background: var(--orange);
        text-decoration: none;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
    }
    
}

// .NavbarItems {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0 5vw;
//     background-color: #222;
//     color: #fff;
//     box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
//     width: 90%;
//     height: 65px;
//     border-radius: 13px;
//     position: fixed;
//     top: 0;
//     left: 50%;
//     transform: translate(-50%);
//     z-index: 99999;
// }

// .navbar-logo {
//     color: #fff;
//     font-size: 2rem;
//     cursor: pointer;
// }

// .nav-menu {
//     display: flex;
//     list-style: none;
//     align-items: center;
//     color: #fff;
// }

// .nav-links {
//     text-decoration: none;
//     color: #ffffff;
//     font-size: 1.2rem;
//     font-weight: 600;
//     padding: 0.7rem 1rem;
//     white-space: nowrap;
// }

// .nav-links:hover {
//     background-color: var(--orange);
//     color: #fff;
//     border-radius: 4px;
//     transition: all 0.2s ease-in-out;
// }

// .nav-links-mobile {
//     display: none;
// }

// .menu-icons {
//     display: none;
// }

// @media screen and (max-width: 850px) {
//     .menu-icons {
//         display: block;
//         cursor: pointer;
//     }

//     .nav-menu {
//         flex-direction: column;
//         justify-content: flex-start;
//         background-color: #3d3d3d;
//         width: 100%;
//         height: 100vh;
//         position: absolute;
//         top: 65px;
//         left: -100%;
//         opacity: 0;
//         padding: 2rem 0;
//         transition: 0.3s ease-in-out;
//     }

//     .nav-menu.active {
//         left: 0;
//         opacity: 1;
//         transition: 0.3s ease-in-out;
//     }

//     .nav-links {
//         display: block;
//         width: 100%;
//         font-size: 1.2rem;
//         padding: 1.5rem 0;
//         text-align: center;
//     }

//     .nav-links:hover {
//         background: var(--orange);
//         transition: none;
//     }

//     .nav-links-mobile {
//         display: block;
//         text-align: center;
//         padding: 1.5rem;
//         margin: auto;
//         border-radius: 4px;
//         width: 80%;
//         background: var(--orange);
//         text-decoration: none;
//         color: #fff;
//         font-size: 1.2rem;
//         font-weight: 700;
//         letter-spacing: 2px;
//     }
// }