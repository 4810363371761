.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.partners-header{
    display: flex;
    gap: 1rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
    margin-top: 13rem;
    text-align: center;
}
.program-categories{
    display: flex;
    gap: 1rem;

}
.category{
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
    cursor: pointer;

}
.category:hover{
    background-color: var(--orange);
}
.category>:nth-child(1){
    width: 2rem;
    height: 2rem; 
    fill: white;

}

.category>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;

}
.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px
}
.partner{
    color:  #f48915 !important
}
.Reasons{
    padding: 0 2rem;
    display: flex;
    grid-template-areas: 2rem;
    margin-top: 10px;
}
.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r>img{
    object-fit: cover;
}
.left-r>:nth-child(1){
   width: 12rem;
   grid-row: 1/3;
   height: 28rem;
}
.left-r>:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
 }
 .left-r>:nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;

 }
 .left-r>:nth-child(4){
    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;

 
 }

.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-r>span{
    font-weight: bold;
    color: var(--orange);
}
.right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}
.details-r>div>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
}
.partners>img{
    width: 2.5rem;
}
.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan:nth-child(2){
font-size: 1rem;
font-weight: bold;
}

.plan:nth-child(5){
font-size: 0.8rem;
}
.Testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
    font-size: 35px;
}
.left-t>:nth-child(2),
.left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.left-t>:nth-child(4){
 text-transform: none;
 text-align: justify;
 letter-spacing: 2px;
 line-height: 40px;
}
.right-t{
    flex: 1;
    position: relative;
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;

}
.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background: var(--planCard);
}
.head{
    color: var(--orange);
    font-weight: bold;
    font-size: 35px;
}
.ente{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.arrows{
display: flex;
gap: 1rem;
position: absolute;
bottom: 1rem;
left: 3rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}
.join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
    justify-content: center;
    align-items: center;
}
.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid orange;
    border-radius: 20%;
    margin: -10px 0;
}
.left-j>div{
    display: flex;
    gap: 1rem;
}
.right-j{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgrayz);
}
::placeholder{
    color: var(--lightgray);   
}
.btn-j{
    background-color: var(--orange);
    color: white;

}
.plans-blur-1{
width: 32rem;
height: 23rem;
top: 6rem;
left: 0;
}
.join{
    margin-top: 25px;
}
.Testimonials{
    margin-top: 35px;
}
.plans-blur-2{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    right: 0;
    }
.ente{
    width: 100%;
    background-color: aqua;
}
.gallery{
    width: 100%;
}
#gallerie{
    padding-top: 20px;
    padding-bottom: 20px;
}
    @media screen and (max-width: 768px) {
        .programs-header,.partners-header{
            flex-direction: column;
            gap: 1rem;
            font-size: x-large;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
        } 
        .program-categories{
            flex-direction: column;
        }
        .Reasons{
            flex-direction: column;

        }
        .left-r{
            grid-auto-rows: auto;
            overflow: hidden;
        }
        .left-r > :nth-child(1){
            width: 7rem;
            height: 17rem;
        }
        .left-r > :nth-child(2){
            width: 15rem;
            height: 10rem;
        }
        .left-r > :nth-child(3){
            width: 7rem;
        }
        .left-r > :nth-child(4){
            width: 7rem;
            height: 6rem;
        }
        .plans-container{
            margin-top: 10px;
        }
        .plans{
            flex-direction: column;
        }
        .Testimonials{
            flex-direction: column;
            margin-top: 10px;

        }
        .left-t>:nth-child(2),
.left-t>:nth-child(3){
    font-size: xx-large;
}
.right-t{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.right-t>div{
    position: relative;
    display: none;
}
.right-t>img{
    top: 0;
    right: 0;
    position: relative;
    align-self: center;
}
.right-t>:last-child{
    display: block;
    bottom: 0;
    left: 0;
}
.join{
    flex-direction: column;
    gap: 1rem;
    margin-top: 15px;
}
.left-j{
    font-size: x-large;
    flex-direction: column;
}
.right-j{
    padding: 2rem;
}
    }
